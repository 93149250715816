<template lang="pug">
    Dialog.vizualizar-transacao-cartao(
        header="Vizualizar Pagamento"
        :visible.sync='show' :modal='true'
        :contentStyle="{overflowY: 'auto !important'}"
    )
        .p-grid.p-fluid(v-if="model" style="padding-bottom:0")
            .p-col-12.p-md-6
                label.form-label Cliente:
                InputText.full-opacity(disabled v-model="model.nm_pessoa_fisica")

            .p-col-6.p-md-3
                label.form-label CPF:
                InputText.full-opacity(disabled v-model="model.nr_cpf_f")

            .p-col-6.p-md-3
                label.form-label Telefone:
                InputText.full-opacity(disabled v-model="model.nr_telefone_f")

            .p-col-4.p-md-3
                label.form-label Guia:
                InputText.full-opacity(disabled v-model="model.cd_transacao_financeira")

            .p-col-8.p-md-6
                label.form-label Caixa:
                InputText.full-opacity(disabled v-model="model.nm_caixa")

            .p-col-6.p-md-3.ta-center
                label.form-label Resgistro Manual:
                InputSwitch.full-opacity(disabled v-model='model.ie_registro_manual')

            .p-col-6.p-md-4
                label.form-label Operadora:
                InputText.full-opacity(disabled v-model="model.ds_operadora")

            .p-col-6.p-md-4
                label.form-label Plataforma:
                InputText.full-opacity(disabled v-model="model.acquirer_name")

            .p-col-6.p-md-4
                label.form-label Forma de pagamento:
                InputText.full-opacity(disabled v-model="model.ds_forma_pagamento")

            .p-col-6.p-md-4.ta-center
                label.form-label Situação:
                p.mtd-badge(:style="{'background-color': model.ds_situacao_cor}") {{ model.ds_situacao }}

            .p-col-6.p-md-4
                label.form-label Motivo situação:
                InputText.full-opacity(disabled v-model="model.status_reason")

            .p-col-4.p-md-4
                label.form-label Autorização:
                InputText.full-opacity(disabled v-model="model.authorization_code")

            .p-col-8.p-md-6
                label.form-label PIX DOC:
                InputText.full-opacity(disabled v-model="model.pix_doc")

            .p-col-4.p-md-3
                label.form-label Valor venda:
                CurrencyInput.full-opacity(disabled :value="model.nr_valor || 123")

            .p-col-4.p-md-3
                label.form-label Valor juros:
                CurrencyInput.full-opacity(disabled v-model="model.nr_valor_acrescimo")

            .p-col-4.p-md-3
                label.form-label Valor total:
                CurrencyInput.full-opacity(disabled v-model="model.amount")

            .p-col-12.mt-2.mb-2.p-grid
                .p-col-12.p-md-6.aux-2
                    span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                .p-col-12.p-md-6.aux-2
                    span <b>Data da criação: </b> {{ model.dt_criado_f }}

</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import CurrencyInput from "@/components/CustomComponents/CurrencyInput";

export default {
    components: { CurrencyInput, Dialog, InputSwitch, InputText },
    props: ['visible', 'model'],
    computed: {
        show: {
            get() { return this.visible; },
            set(value) { if (!value) this.$emit('close'); }
        }
    },
    data() {
        return {
            submitted: false,
            waiting: false,
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">
.vizualizar-transacao-cartao {
    width: 98%;
    max-width: 700px;

    .full-opacity {
        opacity: 1 !important;
    }
    .mtd-badge {
        font-size: 14px;
        padding: 6px 12px;
    }
}
</style>
