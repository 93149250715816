<template lang="pug">
    .main-wrapper.transacoes-cartoes-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / Caixa / <b>Transações Cartões</b>

        DialogVizualizar(
            :model="dialogDetail.model"
            :visible="dialogDetail.visible"
            @close="closeDetail"
        )

        Dialog.dialog-relatorio(header="Gerar relatório" :modal="true" :visible.sync="dialogRelatorio")
            .ta-center
                p Selecione o formato de exportação:
                .mt-4.my-2
                    ProgressSpinner.small-spinner(v-if="waiting.includes('relatorio')" strokeWidth="4")
                    div(v-else)
                        Button.p-button.mr-3(label="CSV" icon="jam jam-document" @click="gerarRelatorio('csv')")
                        Button.p-button.mr-3(label="EXCEL" icon="jam jam-document" @click="gerarRelatorio('xlsx')")
                        Button.p-button.mr-3(label="PDF" icon="jam jam-document" @click="gerarRelatorio('pdf')")

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-5.p-lg-4
                    label.form-label Cliente:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_pessoa_fisica'
                            @keyup='checkEmptyField("nm_pessoa_fisica")' )
                        Button(icon='jam jam-search' @click='applyFilters()' :disabled="waiting.includes('list')")

                .p-col-12.p-md-3.p-lg-2
                    label.form-label CPF:
                    .p-inputgroup
                        InputMask(
                            mask='999.999.999-99'
                            placeholder='CPF'
                            :autoClear='false'
                            v-model='filters.nr_cpf'
                            @keydown.enter.native='applyFilters()'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' :disabled="waiting.includes('list')")

                .p-col-12.p-md-4.p-lg-3
                    label.form-label Situação:
                    MultiSelect(
                        v-model='filters.ie_situacao'
                        :options='options.situacao'
                        optionLabel='ds_valor'
                        optionValue='ie_valor'
                        @change='applyFilters()')

                .p-col-12.p-md-4.p-lg-3
                    label.form-label Forma de pagamento:
                    MultiSelect(
                        v-model='filters.cd_forma_pagamento_list'
                        :options='options.formasPagamento'
                        optionLabel='text'
                        optionValue='value'
                        @change='applyFilters()')

                .p-col-12.p-md-4.p-lg-2
                    label.form-label Cod. Autorização:
                    .p-inputgroup
                        InputText(@keyup.enter.native='applyFilters()' v-model='filters.authorization_code'
                            @keyup='checkEmptyField("authorization_code")' )
                        Button(icon='jam jam-search' @click='applyFilters()' :disabled="waiting.includes('list')")

                .p-col-12.p-md-4.p-lg-3
                    label.form-label PIX DOC:
                    .p-inputgroup
                        InputText(@keyup.enter.native='applyFilters()' v-model='filters.pix_doc'
                            @keyup='checkEmptyField("pix_doc")' )
                        Button(icon='jam jam-search' @click='applyFilters()' :disabled="waiting.includes('list')")

                .p-col-12.p-md-3.p-lg-1
                    label.form-label Guia:
                    .p-inputgroup
                        InputText(@keyup.enter.native='applyFilters()' v-model='filters.cd_transacao_financeira'
                            @keyup='checkEmptyField("cd_transacao_financeira")' )
                        Button(icon='jam jam-search' @click='applyFilters()' :disabled="waiting.includes('list')")

                .p-col-12.p-md-3.p-lg-2
                    label.form-label Registrado Manualmente:
                    Dropdown(
                        v-model='filters.ie_registro_manual'
                        :options='options.resgistroManual'
                        optionLabel='text'
                        optionValue='value'
                        @change='applyFilters()')

                .p-col-6.p-md-3.p-lg-2
                    label.form-label Data inicial:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_final'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(
                            icon='jam jam-rubber'
                            v-tooltip.top="'Limpar'"
                            @click='filters.dt_inicial = null; applyFilters()'
                            :disabled="waiting.includes('list')"
                        )

                .p-col-6.p-md-3.p-lg-2
                    label.form-label Data final:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_final' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_inicial'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(
                            icon='jam jam-rubber'
                            v-tooltip.top="'Limpar'"
                            @click='filters.dt_final = null; applyFilters()'
                            :disabled="waiting.includes('list')"
                        )

                .p-col-12.p-md-4.p-lg-3
                    label.form-label Tipo de Caixa:
                    MultiSelect(
                        :options='options.tipoCaixa'
                        optionLabel='ds_valor'
                        optionValue='ie_valor'
                        @change='applyFilters()'
                        v-model='filters.ie_tipo'
                        showClear
                        filter
                    )

                .p-col-12.p-md-4.p-lg-3
                    label.form-label Caixa:
                    ProgressBar(v-if="waiting.includes('caixas')" mode="indeterminate")
                    MultiSelect(
                        v-else
                        v-model='filters.cd_caixa'
                        :options='options.caixas'
                        dataKey='id'
                        optionLabel='nm_caixa'
                        optionValue='id'
                        placeholder='- TODOS -'
                        @change='applyFilters()'
                        filter
                    )

                .p-col-12.p-md-4.p-lg-3
                    label.form-label Unidade:
                    ProgressBar(v-if="waiting.includes('unidades')" mode="indeterminate")
                    MultiSelect(
                        v-else
                        :options='options.unidades'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        @input='getSetor()'
                        v-model='filters.cd_unidade'
                        filter
                    )
                .p-col-12.p-md-4.p-lg-3
                    label.form-label Setor:
                    ProgressBar(v-if="waiting.includes('setores')" mode="indeterminate")
                    MultiSelect(
                        v-else
                        :options='options.setores'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        @input='getSalas()'
                        v-model='filters.cd_setor'
                        :disabled="!filters.cd_unidade?.length"
                        v-tooltip.bottom="!filters.cd_unidade?.length ? 'Informe a unidade' : ''"
                        filter
                    )

                .p-col-12.p-md-4.p-lg-3
                    label.form-label Sala:
                    ProgressBar(v-if="waiting.includes('salas')" mode="indeterminate")
                    MultiSelect(
                        v-else
                        :options='options.salas'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_sala'
                        :disabled="!filters.cd_setor?.length"
                        v-tooltip.bottom="!filters.cd_setor?.length ? 'Informe o setor' : ''"
                        filter
                    )

                .p-col-12.p-md-4.p-lg-3
                    label.form-label Operadora:
                    ProgressBar(v-if="waiting.includes('operadoras')" mode="indeterminate")
                    MultiSelect(
                        v-else
                        :options='options.operadoras'
                        optionLabel='ds_operadora'
                        optionValue='ie_gateway'
                        @change='applyFilters()'
                        v-model='filters.acquirer_name'
                    )

                .p-col-6.p-md-3.p-xl-2
                    Button(:disabled="waiting.includes('list')" label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

                .p-col-6.p-md-3.p-xl-2
                    Button.p-button-warning(
                        :disabled="waiting.includes('list')"
                        label='Gerar relatório'
                        icon='jam jam-printer'
                        @click="(filters.dt_inicial && filters.dt_final) ? dialogRelatorio = true : $toast.error('Adicione data inicial e data final', { duration: 3000 })"
                    )

        ProgressBar(v-if="waiting.includes('list')" mode="indeterminate")
        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)

            .p-grid.p-col-12.pr-0
                .p-col-4.mt-1
                    InputSwitch.mr-2(v-model='mostrarSelecionados' :disabled='!selecionados.length')
                    label.label-mostrar-selecionados Mostrar somente selecionados
                .p-col-5.ta-center.mt-1.pr-0
                        p.text-soma.ma-0(v-if='somaTotal') <b>Total Filtrado:</b> {{ formattedSum(somaTotal) }}
                        p.text-soma.ma-0.ml-2 <b>Selecionados:</b> {{ selecionados.length }}
                        p.text-soma.ma-0.ml-2 <b>Soma Selecionados:</b> {{ formattedSum(sum) }}
                        a.link-limpar.ml-2.mr-0.mt-1(v-if='selecionados.length > 0' @click='limparSelecao()') Limpar seleção
            Panel(header='Lista de Transações Cartões')
                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")
                DataTable.datatable.ta-center(
                    :value="listaDinamica"
                    :metaKeySelection="false"
                    dataKey="id"
                )
                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center')
                        template(#header='{data}')
                            Checkbox(v-if='mostrarSelecionados ? selecionados.length : list.length' v-model='selecionarTodos' binary)
                        template(#body='{data}')
                            Checkbox(v-model='selecionados' :value='data' :id='data.id' @click.stop)
                    Column(headerStyle='width: 10%;' field='dt_criado_f' header='Data')
                    Column(headerStyle='width: 8%;' field='cd_transacao_financeira' header='Guia')

                    Column(headerStyle='width: 20%;' bodyStyle='text-align: left' field='nm_pessoa_fisica' header='Nome do paciente')
                        template(#body='props')
                            span {{ props.data.nm_pessoa_fisica }} <br>
                            div(class="small-text")
                                span <b>CPF:</b> {{ props.data.nr_cpf_f }} <br>
                                span <b>Telefone:</b> {{ props.data.nr_telefone_f }}

                    Column(headerStyle='width: 15%;' field='ds_operadora' header='Operadora')

                    Column(headerStyle='width: 12%;' field='cd_forma_pagamento' header='Forma de pagamento')
                        template(#body='props')
                            span {{ getDescricaoFormaPgmn(props.data.cd_forma_pagamento) }}
                            span(v-if="props.data.cd_forma_pagamento === 5")  ({{ props.data.installments }}x)

                    Column(headerStyle='width: 15%;' bodyStyle='text-align: left' field='ie_registro_manual' header='Autorização')
                        template(#body='props')
                            span <b>Autorização:</b> {{ props.data.authorization_code }}<br>
                            span <b>PIX DOC:</b> {{ props.data.pix_doc }}<br>
                            span <b>Registro manual:</b>
                            span(:class="['tag-registro', {red: !props.data.ie_registro_manual}]") {{ props.data.ie_registro_manual ? "Sim" : "Não" }}

                    Column(headerStyle='width: 10%;' bodyStyle='padding:0' field='ie_situacao' header='Situação')
                        template(#body='props')
                            .cell(:style="{'background-color': props.data.ds_situacao_cor}")
                                span {{ props.data.ds_situacao }} <br />

                    Column(headerStyle='width: 10%;' field='amount_f' header='Valor')

                    Column(headerStyle='width: 10%; text-align: center' header="Ações")
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Vizualizar'"
                                    icon="pi pi-external-link"
                                    @click="openDetail(props.data)"
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                  v-if='props.data.comprovante'
                                    v-tooltip.top="'Imprimir Comprovante'"
                                    icon="jam jam-printer"
                                    @click="imprimirComprovante(props.data.comprovante)"
                                )

                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")
</template>

<style lang="scss">
.transacoes-cartoes-listar {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 20px 0;
        }
        .tag-registro {
            margin-left: 2px;
            padding: 2px 4px;
            border-radius: 10%;
            background-color: #e4f8e1;
            &.red { background-color: #fc9595; }
        }
    }
    .small-text {
        font-size: 12px;
    }
    .link-limpar {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        font-weight: 700;
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 6px;
    }
    .text-soma {
        margin-bottom: 4px;
        margin-right: 10px;
        display: inline-block;
        background-color: #faf3dd;
        box-shadow: 0 1px 2px #ddd;
        padding: 6px 10px;
    }
    .label-mostrar-selecionados {
        vertical-align: super;
        font-size: 12px;
        font-weight: 550;
    }
    .small-spinner {
        width: 28px;
        height: auto;
    }
    .dialog-relatorio {
        width: 500px;
        max-width: 98%;
        overflow-y: auto !important;
    }
}
</style>

<script>
import ProgressBar from "primevue/progressbar";
import DataView from "primevue/dataview";
import Panel from "primevue/panel";
import Paginator from "primevue/paginator";
import DataTable from "primevue/datatable";
import Calendar from 'primevue/calendar'
import Column from "primevue/column";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import MultiSelect from "primevue/multiselect";
import ProgressSpinner from "primevue/progressspinner";
import Dropdown from "primevue/dropdown";
import Checkbox from 'primevue/checkbox'
import InputSwitch from 'primevue/inputswitch'

import moment from "moment";
import {TransacaoCartao, DominioValor, Caixas, UnidadesMedclub, Operadora, Relatorio} from "@/middleware";
import { pCalendarLocale_ptbr, fixTelefoneFormat, processDominiosResponse, formatPrice, formatCPF } from "@/utils";
import wsConfigs from "./../../middleware/configs";
import _ from 'lodash'
import DialogVizualizar from "@/components/TransacoesCartoes/DialogVizualizar";

export default {
    created() {
        this.waiting.push('list');
        Promise.all([
            this.getDominios(),
            this.getCaixas(),
            this.getUnidades(),
            this.getOperadoras(),
        ]).then(() => {
            this.applyFilters();
        });
    },
    components: {
        DialogVizualizar,
        ProgressBar, DataView, Panel, Paginator, DataTable, Column, Button, Tooltip,
        Calendar, Dialog, ProgressSpinner, MultiSelect, InputText, InputMask, Dropdown, Checkbox, InputSwitch
    },
    directives: { tooltip: Tooltip },
    data() {
        return {
            list: [],
            selecionados: [],
			mostrarSelecionados: false,
			somaTotal: 0,
            ptbr: pCalendarLocale_ptbr,
            windowInnerWidth: window.innerWidth,
            dominio: {},
            waiting: [],
            dialogDetail: {
                visible: false,
                model: null
            },
            dialogRelatorio: false,
            options: {
                formasPagamento: [
                    { value: 5, text: "Crédito" },
                    { value: 6, text: "Débito" },
                    { value: 3, text: "Pix / Transferência" },
                    { value: 17, text: "Boleto" },
                ],
                situacao: [],
                resgistroManual: [
                    { text: "TODOS", value: null },
                    { text: "Sim", value: true },
                    { text: "Não", value: false },
                ],
                tipoCaixa: [],
                unidades: [],
                setores: [],
                salas: [],
                operadoras: [],
            },
            filters: {
                nm_pessoa_fisica: "",
                nr_cpf: "",
                ie_situacao: null,
                authorization_code: "",
                pix_doc: "",
                ie_registro_manual: null,
                dt_inicial: null,
                dt_final: null,
                cd_transacao_financeira: null,
                acquirer_name: [],
                cd_forma_pagamento_list: [],
                ie_tipo: [],
                cd_unidade: [],
                cd_setor: [],
                cd_sala: [],
            },
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0,
            },
            paginadorDinamico: {
                rows: wsConfigs.paginator_perPage,
                first: 0,
                total: 0,
                page: this.$route.query.pg || 1,
                onPage: null
            },
        };
    },
    watch: {
        'selecionados': function(val) {
            if (!val.length)
                this.mostrarSelecionados = false
            else if (this.mostrarSelecionados)
                this.paginadorDinamico['total'] = val.length
        },
        mostrarSelecionados: function() {
            this.atualizaPaginacao()
        },
    },
    computed: {
        selecionarTodos:  {
            get() {
                return this.mostrarSelecionados || this.selecionados.length && _.every(this.list, (v) => _.find(this.selecionados, { id: v.id }))
            },
            set(val) {
                if(val)
                    this.selecionados = _.unionBy(this.list, this.selecionados, 'id')
                else
                    this.selecionados = this.mostrarSelecionados ? [] : _.xorBy(this.selecionados, this.list, 'id')
            }
        },
        sum: function() {
            if (!this.selecionados) return 0
            return this.selecionados.reduce((total, item) => total + parseFloat(item.amount ? item.amount: 0), 0)

        },
        listaDinamica: function() {
            if (this.mostrarSelecionados) {
                const reagrupa = _.groupBy(this.selecionados, (v) => _.toInteger((_.indexOf(this.selecionados, v))/this.paginadorDinamico.rows))
                return reagrupa[this.paginadorDinamico.page]
            } else
                return this.list
        }
    },
    methods: {
        getList(params) {
            this.waiting.push('list');
            return TransacaoCartao.findAll(params).then((response) => {
                if (response.status === 200) {
                    this.paginator.count = response.data.count;
                    this.paginadorDinamico.total = response.data.count
                    response.data.results.forEach((transacaoCartao) => {
                        transacaoCartao.nm_pessoa_fisica = transacaoCartao.cd_pessoa_fisica? transacaoCartao.cd_pessoa_fisica.nm_pessoa_fisica : "";
                        transacaoCartao.dt_criado_f = moment(transacaoCartao.dt_criado).format("DD/MM/YYYY HH:mm:ss");
                        transacaoCartao.nr_telefone_f = transacaoCartao.cd_pessoa_fisica? fixTelefoneFormat(transacaoCartao.cd_pessoa_fisica.nr_telefone) : "";
                        transacaoCartao.nr_cpf_f = formatCPF(transacaoCartao.cd_pessoa_fisica?.nr_cpf);
                        transacaoCartao.amount_f = formatPrice(transacaoCartao.amount);
                        transacaoCartao.ds_forma_pagamento = this.getDescricaoFormaPgmn(transacaoCartao.cd_forma_pagamento);
                        transacaoCartao.ds_operadora = this.getDescricaoOperadora(transacaoCartao.acquirer_name);
                        transacaoCartao.ds_situacao = this.getSituacao(transacaoCartao.ie_situacao)?.['ds_valor'];
                        transacaoCartao.ds_situacao_cor = this.getSituacao(transacaoCartao.ie_situacao)?.['ds_cor'] || '#fff';

                        if(!transacaoCartao.ie_registro_manual && transacaoCartao.object_pagarme) {
                          if(_.isArray(transacaoCartao.object_pagarme.intencoesVendas)) {
                            transacaoCartao['comprovante'] = transacaoCartao.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente
                          } else {
                            transacaoCartao['comprovante'] = transacaoCartao.object_pagarme?.intencoesVendas?.pagamentosExternos[0]?.comprovanteAdquirente
                          }
                        }

                    });
                    this.list = response.data.results;
                    this.somaTotal = response.data.total
                }
                this.waiting = this.waiting.filter(x => x !== 'list');
                this.atualizaPaginacao()
                return true;
            });
        },
        applyFilters(page) {
            this.paginator.page = page || 1;
            let params = { paginacao: true, page: page || 1, per_page: this.paginator.per_page };

            if (this.$route.query.pg !== String(this.paginator.page)) {
                this.$router.replace({ query: { pg: String(this.paginator.page) } });
            }

            this.makeFilters(params);
            this.getList(params);
        },
        makeFilters(params) {
            Object.keys(this.filters).forEach((key) => {
                if (!["undefined", "null", ""].includes(String(this.filters[key]))) {
                    if (key === 'nr_cpf') params[key] = this.filters[key].replace(/\D/g, "");
                    else params[key] = this.filters[key];
                }
            });
        },
        onPage(ev) {
            this.paginadorDinamico.page = ev.page + 1
            this.applyFilters(this.paginadorDinamico.page)
        },
        getDominios() {
            this.waiting.push('dominio');
            return DominioValor.findAll({ds_mnemonico: ['SITUACAO_TRANSACAO_CARTAO', 'TIPO_CAIXA']}).then(response => {
                this.waiting.dominio = false;
                this.dominios = processDominiosResponse(response)
                if (response.status === 200) {
                    this.options.situacao = response.data['SITUACAO_TRANSACAO_CARTAO']?.['valores'];
                    this.options.tipoCaixa = response.data['TIPO_CAIXA']?.['valores'];
                }
                this.waiting = this.waiting.filter(x => x !== 'dominio');
            });
        },
        getSituacao(ie_valor) {
            return this.dominios['SITUACAO_TRANSACAO_CARTAO']?.[ie_valor];
        },
        getDescricaoFormaPgmn(id) {
            return this.options.formasPagamento.find(x => x.value === id)?.text || "";
        },
        getDescricaoOperadora(acquirer_name) {
            return this.options.operadoras.find(x => x.ie_gateway === acquirer_name)?.ds_operadora || "";
        },
        getCaixas() {
            this.waiting.push('caixas');
            return Caixas.findAll().then(response => {
                if (response.status === 200) {
                    this.options.caixas = response.data;
                    this.options.caixas.unshift({ id: null, nm_caixa: "- TODOS -" });
                }
                this.waiting = this.waiting.filter(x => x !== 'caixas');
            });
        },
        getUnidades (){
            this.waiting.push('unidades');
            return UnidadesMedclub.findAll().then(response=>{
                if (response.status === 200) {
                    this.options.unidades = response.data.map(u => ({ label: u.nm_unidade, value: u.id }));
                }
                this.waiting = this.waiting.filter(x => x !== 'unidades');
            })
        },
        getSetor (){
            this.options.setores = [];
            this.filters.cd_setor = null;
            this.getSalas();
            if (!this.filters.cd_unidade) return;

            this.waiting.push('setores');
            UnidadesMedclub.findAllSetor({ cd_unidade: this.filters.cd_unidade }).then(response=>{
                if (response.status === 200) {
                    this.options.setores = response.data.map(s => ({label: s.nm_setor, value: s.id}));
                }
                this.waiting = this.waiting.filter(x => x !== 'setores');
            })
        },
        getSalas (){
            this.options.salas = [];
            this.filters.cd_sala = [];
            if (!this.filters.cd_setor) return;

            this.waiting.push('salas');
            UnidadesMedclub.findAllSala({cd_setor: this.filters.cd_setor}).then(response=>{
                if (response.status === 200) {
                    this.options.salas = response.data.map(s => ({ label: s.nm_sala, value: s.id }));
                }
                this.waiting = this.waiting.filter(x => x !== 'salas');
            });
        },
        getOperadoras() {
            this.waiting.push('operadoras')
            return Operadora.findAll().then(response => {
                this.waiting = this.waiting.filter(x => x !== 'operadoras');
                if (response.status === 200) {
                    this.options.operadoras = response.data;
                }
            });
        },
        checkEmptyField (field) {
            if (! this.filters[field].length) this.applyFilters()
        },
        openDetail(data) {
            this.dialogDetail.model = Object.assign({}, data);
            this.dialogDetail.visible = true;
        },
        closeDetail() {
            this.dialogDetail.model = null;
            this.dialogDetail.visible = false;
        },
        limparSelecao () {
            this.selecionados = []
        },
        clearFilters() {
            Object.keys(this.filters).forEach((key) => {
                if (typeof this.filters[key] === 'string') this.filters[key] = "";
                else if (typeof this.filters[key] === 'object' && this.filters[key]?.length) this.filters[key] = [];
                else this.filters[key] = null;
            });
            this.applyFilters();
        },
        imprimirComprovante(comprovante) {
            const comp = `<div style="white-space: pre-wrap;font-family: Open Sans, Helvetica Neue, sans-serif;
					font-stretch: condensed;
					box-sizing: border-box;
					font-size: 0.7rem;
					line-height: 0.9rem;">${comprovante}<div>`;

            const blob = new Blob([comp], { type: "text/html; charset=utf-8" });
            let formData = new FormData();
            formData.append("file", blob);

            fetch("http://localhost:9990/imprimir", {
                method: "POST",
                body: formData,
            }).then(() => {
                this.$toast.success("Comprovante impresso", { duration: 3000 })
            }).catch(() => {
                this.$toast.error("Sem comunicação com a impressora", {duration: 3000});
            })
        },
        formattedSum (sum) {
            return formatPrice(sum)
        },
        atualizaPaginacao () {
            this.paginadorDinamico['page'] = this.mostrarSelecionados 
                                        ? 0 
                                        : this.paginator.page  || 1
            ;

            [	
                this.paginadorDinamico['first'],
                this.paginadorDinamico['total'],
                this.paginadorDinamico['onPage'],
            ] = this.mostrarSelecionados ? [
                0,
                this.selecionados.length,
                ({ page })  => {
                    this.paginadorDinamico.page = page;
                    this.paginadorDinamico.first = this.paginadorDinamico.page * this.paginadorDinamico.rows;
                },
            ] : [
                ((this.paginadorDinamico.page - 1) * this.paginadorDinamico.rows),
                this.paginator.count,
                this.onPage,
            ]
        },
        gerarRelatorio(formato) {
            const dataSend = { ie_tipo_relatorio: formato, nr_relatorio: 43 };
            this.makeFilters(dataSend);

            this.waiting.push('relatorio');
            Relatorio.createGenerico(dataSend).then(response => {
                this.waiting = this.waiting.filter(x => x !== 'relatorio');
                this.dialogRelatorio = false;

                if ([400, 403].includes(response.status)) {
                    if (response.data.detail) this.$toast.error(response.data.detail, { duration: 3000 });
                } else if ([200, 201].includes(response.status)) {
                    // Abrir relatórios em nova aba o
                    window.open('/relatorio/listar', '_blank');
                }
            });
        },
    },
};
</script>
